import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticQuery, graphql } from "gatsby"

const PrivacyNotice = ({ contentField }) => {
  const lang = "en"
  const seo = contentField.seo
  const image = contentField.featuredImage
  return (
    <Layout
      lang={lang}
      translationDE="/de/privacy-policy/"
      translationES="/es/privacy-policy/"
      translationIT="/it/privacy-policy/"
      translationFR="/fr/privacy-policy/"
    >
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={image ? image.sourceUrl : "https://hiddenfjord.com/og-image.png"}
      />

      <div className="main-section main-section--policy">
        <div
          className="text-container"
          dangerouslySetInnerHTML={{ __html: contentField.content }}
        />
      </div>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        contentField: wpPage(id: { eq: "cG9zdDozMjc4" }) {
          content
          seo {
            title
            metaDesc
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    `}
    render={data => <PrivacyNotice {...data} />}
  />
)
